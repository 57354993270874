.video-single-container {
    width: calc(100vw - var(--sidebar-width) - var(--sidebar-hoz-margin) - var(--margins));
    height: 100%;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
}

.video-single-container.self-view-only {
    grid-template-columns: 1fr;
}

.video-single-container video-player-container {
    aspect-ratio: 16 / 9;
}

.video-single-container .video-div {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.video-single-container .video-div.placeholder {
    background-color: black;
}

.video-single-container video-player {
    display: block;
    width: 100%;
    height: 100%;
}

.video-single-container label.name-tag {
    position: absolute;
    bottom: 0;
    margin: 0;
    background-color: #00000083;
    color: white;
    font-size: 14px;
    padding: 0 5px;
}

@media (min-width: 1185px) {
    .video-single-container.self-view-only {
        aspect-ratio: 16 / 9;
        height: 100%;
        width: auto;
    }
}