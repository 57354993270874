@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

/* Waiting Room (Default) */
.io-button .buttons-container button {
    background-color: var(--dark-accent);
}

.io-button .buttons-container button:hover {
    background-color: var(--dark-accent-2);
}

/* Meeting Room */
.meeting-room .io-button .buttons-container button {
    background-color: var(--light-blue-2);
}

.meeting-room .io-button .buttons-container button:hover {
    background-color: var(--light-blue-3);
}

.meeting-room .io-button .buttons-container button:disabled {
    background-color: var(--light-grey);
}

.meeting-room .io-button .buttons-container path {
    fill: var(--dark-bright-accent);
}

.meeting-room .io-button .buttons-container button:disabled path {
    fill: var(--dark-medium-grey);
}

/* Common Styles */
.io-button {
    position: relative;
}

.io-button .buttons-container {
    display: flex;
    justify-content: center;
    gap: 2px;
}

.io-button .buttons-container button {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.io-button .on-off-button {
    border-radius: 10px 0 0 10px;
}

.io-button .options-button {
    border-radius: 0 10px 10px 0;
}

.io-button label {
    color: var(--dark-medium-grey);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.876px;
    margin: 0;
    margin-top: 8px;
}

.io-button .io-options-panel-container {
    position: relative;
}

/* Icons */
.io-button .on-off-button .icon-camera-on {
    width: 40px;
}

.io-button .on-off-button .icon-camera-off {
    width: 40px;
}

.io-button .on-off-button .icon-microphone-on {
    width: 24px;
}

.io-button .on-off-button .icon-microphone-off {
    width: 37px;
}

.io-button .on-off-button .icon-speaker-on {
    width: 38px;
}

.io-button .on-off-button .icon-speaker-off {
    width: 38px;
}

.io-button .options-button .icon-options-dots {
    width: 8px;
}