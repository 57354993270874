.vc-sidebar-toggle {
    width: 36px;
    height: 36px;
    padding: 0;
    border-radius: 8px;
    background-color: var(--brand-accent);
    display: flex;
    justify-content: center;
    align-items: center;
}

.vc-sidebar-toggle:hover {
    background-color: var(--brand-accent);
}

.vc-sidebar-toggle .icon-container {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vc-sidebar-toggle .icon-container svg {
    width: 100%;
    height: 100%;
}