.chat {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.chat-window {
    background-color: var(--light-blue);
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.chat.sidebar-open .chat-window {
    border-radius: 0 5px 5px 0;
}

.chat-header {
    background-color: var(--dark-bright-accent);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px 5px 0 0;
}

.chat .header-text {
    padding: 8.5px 10px;
    color: var(--white);
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 600;
    cursor: default;
}

.chat.sidebar-open .chat-header {
    border-radius: 0 5px 0 0;
}

.chat .open-close-toggle {
    background-color: var(--dark-bright-accent-2);
    border-radius: 5px 0 0 0;
    padding: 0 12px;
    height: 100%;
    display: flex;
    align-items: center;
}

.chat.sidebar-open .open-close-toggle {
    border-radius: 0;
}

.chat .icons-caret-left,
.chat .icons-caret-right {
    width: 11px;
    height: 11px;
}

/* Messages */
.chat .messages-container {
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.chat .message-thread {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    cursor: default;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat .message-thread::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat .message-thread {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.chat .message-input-field {
    background-color: var(--white);
    border-radius: 20px;
    padding: 5px 5px 5px 15px;
    display: flex;
    gap: 8px;
}

.chat .message-input-field input {
    width: 100%;
    height: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    flex: 1;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
}

.chat .message-input-field input:focus {
    outline: none;
}

.chat .send-btn {
    width: 28px;
    height: 28px;
    min-width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--brand-accent);
    padding: 0;
    border-radius: 14px;
}

.chat .send-btn:hover {
    background-color: var(--brand-accent-2);
}

.chat .icons-send-arrow-right {
    width: 20px;
    height: 18px;
}