.breakout-rooms {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.breakout-rooms h1 {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 0;
}

.breakout-rooms form {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.breakout-rooms .form-entry {
    display: flex;
    align-items: center;
}

.breakout-rooms label {
    width: 150px;
    text-align: left;
    margin: 0;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 600;
}

.breakout-rooms .right-label {
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
}

.breakout-rooms input {
    padding: 0 5px;
    border-radius: 3px;
    border: 1px solid var(--medium-grey-2);
    background: var(--light-blue);
    font-family: 'Inter';
    color: var(--dark-accent);
    font-size: 11px;
    font-weight: 500;
}

.breakout-rooms .num-rooms-input,
.breakout-rooms .duration-input {
    width: 20px;
    height: 20px;
}

.breakout-rooms .topic-input {
    flex: 1 0 0;
    height: 40px;
}

.breakout-rooms button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--dark-bright-accent);
    color: var(--white);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
}

.breakout-rooms button:hover {
    background-color: var(--dark-bright-accent-2);
}