.chat .message {
    padding: 8px;
    border-radius: 5px;
    max-width: 190px;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    text-align: left;
}

.chat .message .announcement-tag {
    margin-bottom: 3px;
}

.chat .message .info {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 6px;
}

.chat .message .sender-name {
    font-weight: 600;
}

.chat .received-msg {
    background-color: var(--white);
    color: var(--dark-medium-grey);
    align-self: start;
}

.chat .sent-msg {
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
    align-self: end;
}

.chat .sent-msg .announcement-tag {
    background-color: var(--dark-bright-accent);
    color: var(--white);
}

.chat .sent-msg .announcement-tag path {
    fill: var(--white);
}