.participant-list {
    overflow-y: auto;
    flex: 1;
}

.participant-list .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding-right: 10px;
    height: 40px;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 500;
}

.participant-list .item.selected {
    background-color: var(--light-blue);
}

/* Message Button */
.participant-list .message-btn {
    border-radius: 3px;
    padding: 5px 10px;
    background: var(--light-grey);
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
}

.participant-list .message-btn:hover {
    background-color: var(--medium-grey);
}

/* AV Status */
.participant-list .av-status {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.participant-list .av-status div {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.participant-list .av-status svg {
    width: 100%;
    height: 100%;
}

.participant-list .av-status path {
    fill: var(--medium-grey);
}

.participant-list .av-status line {
    stroke: var(--medium-grey);
}

/* Checkbox */
.participant-list .checkbox-name-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.participant-list .checkbox {
    padding: 0;
    width: 14px;
    height: 14px;
    background: none;
    border: 1.5px solid var(--medium-grey);
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.participant-list .checkbox.checked {
    padding: 0;
    width: 14px;
    height: 14px;
    background: none;
    border: 1.5px solid var(--dark-bright-accent);
    border-radius: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.participant-list .icons-checkmark {
    width: 10px;
}

.participant-list .icons-checkmark path {
    fill: var(--dark-bright-accent);
}

.participant-list .spacer {
    width: 14px;
    height: 14px;
}