/* Content div styles */
/* Content div styles */

.programs-home {
    display: flex;
    flex-direction: column;  /* This will stack the children vertically */
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }

  .programs-content {
    display: flex;
    flex-direction: row; /* This will ensure customization and selection are side by side */
    justify-content: space-between;
    padding: 20px;
    align-items: flex-start;
}

/* for class programs-content-container, make the two child div elements arranged horizontal */
.programs-content-container {
    display: flex;
    flex-direction: row; /* This will ensure customization and selection are side by side */
    justify-content: space-between;
    padding: 20px;
    align-items: flex-start;
}


/* Program Customization Sub-Section */
.program-customization {
    flex-basis: calc(70% - 40px); /* subtracting the padding to maintain consistency */
    padding: 12px;
    box-sizing: border-box; /* ensures that padding doesn't increase the total width */
    width: 50%;
}

.program-customization h2, .page-headline {
    text-align: left;
    color: var(--ui-dark-purple, #4E2375);
    font-family: Montserrat;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 218.182% */
    margin-bottom: 39px;
}

.program-customization h3 {
    text-align: left;
    color: var(--ui-dark-purple, #4E2375);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 218.182% */
    margin-top: 20px; /* to space it from the preceding element */
}

.programsSubmitButton {
    border: none;
    border-radius: 46px;
    background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    padding: 0 21px;
    margin-right: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 15px;
  }

.customization-field {
    margin-bottom: 20px;
}

.custom-input {
    width: 100%;
    padding: 10px;
    border-radius: 6px;
    background: #EFEFEF;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15) inset;
    border: none;
    margin-top: 10px;
}

/* Program Selection Sub-Section */
.program-selection {
    flex-basis: calc(30% - 40px); /* subtracting the padding to maintain consistency */
    padding: 20px;
    box-sizing: border-box; /* ensures that padding doesn't increase the total width */
}

.program-selection h2 {
    text-align: left;
    color: var(--ui-dark-purple, #4E2375);
    font-family: Montserrat;
    font-size: 19px;;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 218.182% */
    margin-top: 20px; /* to space it from the preceding element */
}

/* Radio button styles */
.radio-input-programs {
    display: none;
}

.radio-display-programs {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #E2E5FF;
    margin-right: 8px;
    vertical-align: middle;
    transition: background 0.2s, border-color 0.2s;
}

.custom-radio-programs .radio-input:checked + .radio-display-programs {
    border: 2px solid #83659E;
    background: linear-gradient(135deg, #E2E5FF 5.73%, #E5D8FD 91.15%);
}

.radio-col {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
