.vc-sidebar-container {
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--white);
    width: 642px;
    padding: 20px;
    height: calc(100% - 40px);
    border-radius: 10px;
}

.meeting-room .vc-sidebar-container {
    min-height: 330px;
}

.vc-sidebar {
    height: 100%;
    display: flex;
    gap: 12px;
}

.vc-sidebar .side-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.vc-sidebar .header-buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

/* Closed */
.vc-sidebar-container.closed {
    width: 36px;
    padding: 10px;
    height: calc(100% - 20px);
}