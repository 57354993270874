.absentees {
    display: flex;
    flex-direction: column;
}

.absentees h1 {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
}