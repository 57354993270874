.crisis-popup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    z-index: 1;
    top: 60px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    width: 220px;
    max-height: 202px;
}

.crisis-popup.empty {
    width: 220px;
    height: auto;
    color: var(--dark-medium-grey);
    text-align: left;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 110%;
}

.crisis-popup .warning {
    background-color: rgba(235, 68, 88, 0.15);
    color: var(--muted-red);
    border-radius: 3px;
    padding: 5px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    gap: 5px;
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 600;
    line-height: 110%;
}

.crisis-popup .icons-exclamation-triangle {
    height: 20px;
    width: 22px;
}
.crisis-popup .icons-exclamation-triangle path {
    fill: var(--muted-red);
}