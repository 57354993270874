/* Sidebar.css */
.sidebar {
  width: 12%;
  padding-left: 20px;
  padding-top: 36px;
  background-color: #F6F7FC;
  font-style: italic;
  display: flex;
  flex-direction: column;
  margin: 0; 
  height: 100vh; 
}

.sidebar-logo {
  padding-left: 10px;
  margin-bottom: 18px;
}

.peerlogo {
  min-width: 39px;
  height: 39px;
}

.sidebar a {
  color: #3B6B86;
  font-size: 15px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px;
  margin-bottom: 15px;
  text-decoration: none;
  text-align: left;
}

.sidebar a.selected {
  font-style: italic;
  font-weight: 700;
}