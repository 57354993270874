.reports-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 0px; 
  }
  
  .reports-content {
    margin-top: 70px; 
  }
  
  .page-headline {
    margin-left: 2.5vw;
  }
  
  .timeframe-selection,
  .program-selection {
    max-width: 300px;
  }
  
  .recharts-wrapper {
    margin-bottom: 40px;
  }

  .filters {
    display: flex;
    margin-left: 2.5vw;
  }
  
  /* Adjust styling as needed */
  select {
    width: 100%;
    padding: 10px;
    border-radius: 9.5px;
    border: none;
    background: #E5F7FF;
    font-size: 16px;
  }
  
  /* Sample gradient for the bar chart */
  .bar-gradient {
    fill: url(#barGradient);
  }
  