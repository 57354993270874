.toast {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);

    background-color: white;
    color: black;
    padding: 10px 15px;
    border-radius: 5px;

    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}