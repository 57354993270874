/* Shared styles */
.header-button {
    border-radius: 5px;
    padding: 8px;
    height: 36px;
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
}

.header-button:hover {
    background-color: var(--light-blue-3);
}

.header-button .icon-container svg path {
    fill: var(--dark-accent);
}

.header-button.selected {
    border-radius: 5px;
    padding: 8px;
    height: 36px;
    background-color: var(--dark-accent);
    color: var(--white);
}

.header-button.selected:hover {
    background-color: var(--dark-accent-2);
}

.header-button.selected .icon-container svg path {
    fill: var(--white);
}

.header-button.active-screen-share {
    background-color: var(--pale-red);
    color: var(--white);
}

.header-button.active-screen-share:hover {
    background-color: var(--muted-red);
}

.header-button.active-screen-share .icon-container svg path {
    fill: var(--white);
}

.header-button .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.header-button .icon-container svg {
    width: 100%;
    height: 100%;
}

.header-button .header-title {
    margin: 0;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
}

/* Small styles */
.header-button.small {
    width: 36px;
}

/* Large styles */
.header-button.large {
    width: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

/* Chat Button */
.header-button-container {
    position: relative;
}

.header-button-container .notif-dot {
    position: absolute;
    left: 72px;
    top: 10.5px;
    width: 15px;
    height: 15px;
    border-radius: 8px;
    background-color: var(--pale-red);
    color: var(--white);
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}