.pagination-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.pagination-controls button {
    border: none;
    background: none;
    cursor: pointer;
    width: 12px;
    height: 20px;
    padding: 0;
    border-radius: 0;
}

.pagination-controls svg {
    width: 100%;
    height: 100%;
}

.pagination-controls .dots {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.pagination-controls .circle-container {
    height: 5px;
    width: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-controls circle {
    fill: var(--white);
}

.pagination-controls .circle-container.highlighted circle {
    fill: var(--brand-accent);
}