/* CenterBoxes.css */
.center-boxes {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    margin-left: 50px;
  }
  
  .center-boxes a {
    color: var(--ui-dark-purple, #4E2375);
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    margin: 5px 0;
  }
  