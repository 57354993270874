/* Waiting Room */
.waiting-room .time-display {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    height: 60px;
    gap: 10px;
    border-radius: 10px;
    background: var(--dark-bright-accent);
    color: var(--white);
}

.waiting-room .time-display.countpast {
    background-color: var(--pale-red);
}

.waiting-room .time-section h1,
.waiting-room .time-section h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: var(--white);
    margin: 0;
}

.waiting-room .time-section h1 {
    font-size: 20px;
    letter-spacing: 0.2px;
}

.waiting-room .time-section h2 {
    font-size: 10.471px;
}

/* Meeting Room */
.meeting-room .time-display {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
}

.meeting-room .time-display label {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.meeting-room .time-display h1 {
    color: var(--dark-accent, #3B6B86);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}