.meeting-modal {
    border-radius: 15px;
    padding: 30px;
}

.meeting-modal.large {
    border-radius: 20px;
    padding: 40px;
}

.meeting-modal .content {
    width: 390px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    text-align: center;
    font-family: 'Inter';
    font-weight: 500;
    color: var(--dark-medium-grey);
}

.meeting-modal .message {
    font-size: 20px;
}

.meeting-modal.large .message {
    font-size: 24px;
}

.meeting-modal .info-text {
    font-size: 16px;
}

.meeting-modal .button-container {
    display: flex;
    gap: 20px;
    width: 100%;
}

.meeting-modal button {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
}

.meeting-modal .cancel-button {
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
}

.meeting-modal .cancel-button:hover {
    background-color: var(--light-blue-3);
}

.meeting-modal .action-button {
    background-color: var(--dark-bright-accent);
    color: var(--white);
}

.meeting-modal .action-button:hover {
    background-color: var(--dark-bright-accent-2);
}
