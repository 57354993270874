/* General layout */
.dashboard {
    display: flex;
}

/* Sidebar styling */

.sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 3px;
}

.navbar-icon {
    width: 90px;
    height: 90px;
}

.link-button {
    padding: 10px 20px;
    background-color: #FFEE5D;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.link-button:hover {
    background-color: #FFE5A0;
}

.sidebar {
    width: 12%;
    padding-left: 20px;
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    margin: 0; 
    height: 100vh; 
    background: var(--UI-White, #FFF);
    box-shadow: 0px 0px 10px 0px rgba(15, 33, 82, 0.20);
}

.nav-items ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-items ul li {
    margin: 33px 39px;
    font-size: 16px;
    color: #697984;
    text-align: left;
    cursor: pointer;
}

.nav-items li:first-child {
    color: #0080A9;
    padding: 12px;
    width: 100%;
    border-radius: var(--Spacing-spacing-01-5, 6px);
    background: linear-gradient(334deg, #DDF5FB -7.25%, #CFECF5 127.39%), 
                linear-gradient(99deg, #3271BF -29.19%, #0D9CC9 111.09%);
}

/* Content styling */
.content {
    flex: 1;
    padding: 40px;
    background-color: #EEF0F5;
    height: 100vh;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.header h1 {
    color: #182A72;
    font-size: 28px;
    font-weight: 500;
}

.table_content {
    display: flex;
    padding: var(--Spacing-spacing-05, 20px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Spacing-spacing-02, 8px);
    align-self: stretch;
    border-radius: var(--Global-Size-size-02, 8px);
    background: var(--UI-White, #FFF);
    box-shadow: 3px 3px 15px 0px rgba(15, 33, 82, 0.10);
    margin-bottom: 30px;
}

.test-headline {
    color: #3271BF;
}


.search-bar {
    display: flex;
    width: 30% !important;
    height: 96% !important;
    padding: var(--Spacing-spacing-03, 12px);
    align-items: flex-start;
    gap: var(--Spacing-spacing-02, 8px);
    border-radius: var(--Global-Size-size-10, 40px) !important;
    background: var(--UI-PaleGray, #EEF0F5);
}

.new-group {
    padding: 10px 20px;
    border-radius: var(--Global-Size-size-01, 4px);
    background: var(--Gradient-MediumBlue, linear-gradient(99deg, #3271BF -29.19%, #0D9CC9 111.09%));
    color: white;
    border: none;
    cursor: pointer;
    font-weight: 800;
}

.new-group:hover {
    background: var(--Gradient-MediumBlue, linear-gradient(99deg, #3271BF -99.19%, #0D9CC9 111.09%));
}

/* Table styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

thead {
    background-color: #f9fafb;
}

th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

/* Common layout styling for all severities */
.severity {
    display: flex;
    padding: 4px, 8px;
    align-items: center;
    border-radius: 20px;
  }
  
  /* Specific styles for each severity level */
 .One {
    background: #EAEAEA;
  }
  
  .Two {
    background: #FFF7AF;
  }
  
  .Three {
    background: #FFD6AF;
  }
  
  .Four {
    background: #FFD6D6;
  }

/* Responsive adjustments */
@media (max-width: 768px) {
    .dashboard {
        flex-direction: column;
    }
    
    .sidebar {
        width: 100%;
        height: auto;
    }
    
    .content {
        padding: 20px;
    }
}