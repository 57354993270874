.break-modal {
    border-radius: 20px;
    padding: 40px;
}

.break-modal .content {
    width: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.break-modal .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue-2);
    height: 60px;
    width: 60px;
    border-radius: 30px;
}

.break-modal .icon-container svg {
    width: 35px;
    height: auto;
}

.break-modal .icon-container path {
    fill: var(--dark-bright-accent);
}

.break-modal h1 {
    margin: 0;
    margin-bottom: 15px;
    color: var(--dark-bright-accent);
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 700;
}

.break-modal p {
    margin: 0;
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
}

.break-modal button {
    background-color: var(--dark-bright-accent);
    color: var(--white);
    border-radius: 10px;
    padding: 20px;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
}

.break-modal button:hover {
    background-color: var(--dark-bright-accent-2);
}