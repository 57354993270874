.spotlight-card {
    background-color: var(--white);
    border-radius: 5px;
    padding: 10px;
    width: auto;
    min-width: 138px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

/* Top Container */
.spotlight-card .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.spotlight-card .topics-container {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.spotlight-card .distance {
    color: var(--muted-purple);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    text-align: right;
}

/* Resource Titles */
.spotlight-card.condensed .titles {
    cursor: pointer;
}

.spotlight-card .titles h1 {
    color: var(--dark-navy);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}

.spotlight-card .titles h2 {
    color: var(--muted-purple);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    text-align: left;
}

/* Tags */
.spotlight-card .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
}

.spotlight-card .tag {
    border-radius: 2px;
    padding: 4px;
    font-family: 'Inter';
    font-size: 10px;
}

.spotlight-card .issue-tag {
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
    font-weight: 600;
}

.spotlight-card .info-tag {
    background: var(--medium-blue-gradient, --medium-blue);
    color: var(--white);
    font-weight: 700;
}

/* Description */
.spotlight-card .description-text {
    color: var(--purple-grey);
    text-align: left;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 130%;
}

/* Info Tables */
.spotlight-card .info-tables-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
    margin: 5px 0;
}

/* Bottom Container */
.spotlight-card .bottom-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
    margin-top: 4px;
}

.spotlight-card .copy-link-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

/* Copy Link Button */
.spotlight-card .copy-btn {
    padding: 0;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #83ACD2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spotlight-card .copy-btn:hover {
    background-color: #709ac2;
}

.spotlight-card .copy-btn svg {
    width: 7px;
    height: auto;
}

/* Copied Message */
.spotlight-card .copied-msg {
    position: absolute;
    left: 20px;
    background-color: white;
    border-radius: 2px;
    padding: 3px 5px;
    width: 40px;
    height: 8px;
    color: #0D4373;
    font-family: 'Inter';
    font-size: 7px;
    font-weight: 600;
}

.spotlight-card .copied-msg::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-right: 5px solid white;
    border-bottom: 3px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    left: -9px;
    top: 4px;
}

/* Link */
.spotlight-card .resource-link {
    color: var(--purple-grey);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.spotlight-card .resource-link:hover {
    color: var(--muted-purple);
}

/* Save Button (Bookmark Icon) */
.spotlight-card .save-btn {
    padding: 0;
    background: none;
    height: 11px;
}

.spotlight-card .save-btn svg {
    width: 9px;
    height: 11px;
}

.spotlight-card .save-btn path {
    fill: #A0A2B8;
}

.spotlight-card .save-btn:hover path {
    fill: #988ba8;
}

.spotlight-card .save-btn.active path {
    fill: #FE439D;
}