/* WaitingRoom styles */
.waiting-room {
    display: flex;
    flex-direction: column;
    background-color: var(--light-blue);
    max-width: min(1180px, calc(100vw - 130px));
    min-width: 770px;
    max-height: 800px;
    min-height: 550px;
    height: calc(100vh - 130px);
    padding: 50px;
    margin: 15px auto;
    gap: 30px;
    box-shadow: 2px 3px 25px 0px rgba(15, 33, 82, 0.30);
    backdrop-filter: blur(30px);
}

.waiting-room .header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.waiting-room .company-logo {
    width: 199px;
    height: 66px;
}

.waiting-room .header h1,
h2 {
    font-family: 'Inter';
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.waiting-room .header h1 {
    text-align: left;
    color: var(--dark-accent);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.waiting-room .header h2 {
    text-align: left;
    color: var(--dark-medium-grey);
    font-size: 16px;
    font-weight: 500;
}

.waiting-room .header h2 span {
    font-weight: 700;
    cursor: pointer;
}

.waiting-room .main-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    max-height: 464px;
}

.waiting-room .main-content .video-feed-container {
    position: relative;
    background-color: var(--medium-grey);
    aspect-ratio: 4/3;
    height: 100%;
    width: auto;
    border-radius: 16px;
}

.waiting-room .main-content .video-feed-container.side-bar-closed {
    margin: 0 auto;
}

.waiting-room .main-content video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: rotateY(180deg);
}

video::-webkit-media-controls {
    display: none;
}

.waiting-room .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--light-blue);
    padding-top: 15px;
}

.io-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.waiting-room .time-join-container {
    position: relative;
    display: flex;
    gap: 20px;
}

.waiting-room .time-join-container label {
    position: absolute;
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    top: -35px;
}