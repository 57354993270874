
.home {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    height: 100vh;
  }
  
  .left-sidebar {
    min-width: 12%;
    background-color: #F6F7FC;
    font-style: italic;
    font-weight: bold;
    color: #333; 
  }
  
  /* Styles for the content area (top bar and center boxes) */
  .content {
    width: 100%; /* Adjust the width as needed */
    display: flex;
    flex-direction: column;
  }
  
  /* Styles for the right sidebar */
  .right-sidebar {
    width: 20%; /* Adjust the width as needed */
    padding: 20px;
    font-style: italic;
    font-weight: bold;
    color: #333; /* Medium black */
  }
  