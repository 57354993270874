.visualize-audio-bar {
    position: relative;
    display: flex;
    align-items: center;
}

.empty-bar {
    position: absolute;
}

.empty-bar path {
    stroke: var(--medium-grey);
}

.full-bar {
    position: absolute;
}

.full-bar path {
    stroke: var(--brand-accent);
}