/* TopBar.css */
.top-bar {
    display: flex;
    color: #22232E;
    font-family: 'Poppins';
    font-size: 27px;
    font-style: normal;
    font-weight: 900;
    line-height: 33px;
    text-align: left;
    padding: 10px;
    margin-top: 45px;
    margin-left: 25px;
  }
  
  .logo-container {
    margin-top: 1.2px;
  }
  
  .logo {
    width: 120px;
    height: 39px;
    flex-shrink: 0;
    margin-left: 10px;
  }
  