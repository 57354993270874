.chat-thread-tile {
    padding: 6px;
    display: flex;
    flex-direction: column;
    color: var(--dark-medium-grey);
    text-align: left;
    cursor: default;
}

.chat-thread-tile .tile-row {
    height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.chat-thread-tile:hover,
.chat-thread-tile.selected {
    background-color: var(--light-blue);
}

.chat-thread-tile .recipient-name {
    color: var(--dark-bright-accent);
    font-size: 8px;
    font-weight: 600;
    margin-right: 3px;
    overflow: hidden;
    white-space: nowrap;
}

.chat-thread-tile .timestamp {
    font-family: 'Inter';
    font-size: 7px;
    font-weight: 500;
}

.chat-thread-tile .msg-preview {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: 'Inter';
    font-size: 7px;
    font-weight: 500;
    line-height: 10px;
}

.chat-thread-tile .notif-dot {
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 5px;
    margin-left: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pale-red);
    color: var(--white);
    font-family: 'Inter';
    font-weight: 700;
    font-size: 7px;
}

.chat-thread-tile .tags-container {
    height: 10px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    margin-top: 3px;
}