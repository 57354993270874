.right-sidebar {
  width: 200px;
  background-color: #F6F7FC !important;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--ui-dark-purple, #4E2375);
  padding-left: 20px;
  text-align: left;
}

.logout-button {
  border-radius: 46px;
  background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border: none;
  margin-bottom: 20px;
  width: auto;
  height: calc(1.5vw + 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
}

.logout-button:hover {
  background-color: #FFF;
  color: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  box-shadow: 0px 0px 10px rgba(112, 126, 255, 0.3);
}

.notification-text {
  /* No changes here */
}
