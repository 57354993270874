.announcement-tag {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    padding: 3px 4px;
    border-radius: 2px;
    background-color: var(--light-blue-2);
    color: var(--dark-bright-accent-2);
    font-family: 'Inter';
    font-size: 6px;
    font-weight: 600;
    width: fit-content;
}

button.announcement-tag:hover {
    background-color: var(--light-blue-3);
}

.announcement-tag .icons-megaphone {
    width: auto;
    height: 7px;
}

.announcement-tag .icons-megaphone path {
    fill: var(--dark-bright-accent-2);
}

button.filter-tag {
    padding: 0 3px;
    height: 10px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

div.filter-tag {
    padding: 3px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.filter-tag .icons-x {
    width: 5px;
    height: 5px;
    margin-left: 2px;
}

.filter-tag.red {
    background-color: #FDEDEF;
    color: var(--pale-red);
}

button.filter-tag.red:hover {
    background-color: #FFE0E4;
}

button.filter-tag.red.active {
    background-color: var(--pale-red);
    color: var(--white);
}

button.filter-tag.red.active:hover {
    background-color: var(--muted-red);
}

.filter-tag.yellow {
    background-color: #FFEFC5;
    color: var(--brand-accent);
}

button.filter-tag.yellow:hover {
    background-color: #FFE9B0;
}

button.filter-tag.yellow.active {
    background-color: var(--brand-accent);
    color: var(--white);
}

button.filter-tag.yellow.active:hover {
    background-color: var(--brand-accent-2);
}