.spotlight-filter-toggle {
    border-radius: 10px;
    padding: 0;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
}

.spotlight-filter-toggle .inner-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    padding: 4px 8px;
    margin: 1px;
    border-radius: inherit;
    background-color: var(--white);
}

.spotlight-filter-toggle.inactive {
    color: var(--dark-medium-grey);
    background: var(--light-blue-2);
}

.spotlight-filter-toggle.active {
    color: var(--muted-purple);
    background: var(--medium-blue-gradient, --medium-blue);
}

.spotlight-filter-toggle .icons-checkmark {
    width: auto;
    height: 5px;
}

.spotlight-filter-toggle .icons-checkmark path {
    fill: url(#medium_blue_gradient);
}