/* RobotoCondensed.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap');
/* CSS rules for Roboto Condensed font styles */

.icons-closed-captions {
    height: 23px;
    width: 23px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 3px solid var(--dark-bright-accent);
    color: var(--dark-bright-accent);
    display: flex;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 20px;
    font-weight: 500;
}