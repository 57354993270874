.chat-sidebar {
    width: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-sidebar.open {
    width: 120px;
}

.chat-sidebar .search-filter-controls {
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 8px;
    padding: 8px;
    border-bottom: 1px solid var(--light-grey);
}

.chat-sidebar .search-bar {
    width: auto;
    height: 16px;
    padding: 0 6px;
    border: none;
    border-radius: 10px;
    background-color: var(--light-blue);
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 8px;
    font-weight: 500;
}

.chat-sidebar .filters-container {
    height: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.chat-sidebar .filters-container label {
    margin: 0;
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 6px;
    font-weight: 600;
    line-height: normal;
}

.chat-sidebar .msg-threads-container {
    overflow-y: auto;
}