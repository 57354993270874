.error-message-container {
    width: 100%;
}

.error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    padding: 10px;
    background: rgba(235, 68, 88, 0.10);
    color: var(--muted-red);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.error-message .icons-exclamation-triangle {
    width: 17px;
    height: auto;
    min-width: 17px;
}

.error-message .icons-exclamation-triangle path {
    fill: var(--muted-red);
}

.vc-sidebar .error-message {
    font-size: 11px;
}

.vc-sidebar .error-message .icons-exclamation-triangle {
    width: 15px;
    height: auto;
    min-width: 15px;
}