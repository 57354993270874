.spotlight-topic-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: var(--dark-pink-gradient, #DA61D4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.spotlight-topic-icon svg {
    width: 12px;
    height: auto;
}