.error-modal {
    border-radius: 20px;
    padding: 40px;
}

.error-modal-content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    width: 470px;
}

.error-modal .icon-container {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgba(235, 68, 88, 0.20);
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-modal .icons-exclamation-triangle {
    width: auto;
    height: 35px;
}

.error-modal .icons-exclamation-triangle path {
    fill: var(--muted-red);
}

.error-modal .text-container {
    color: var(--dark-medium-grey);
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
}

.error-modal .text-container h1 {
    margin: 0;
    margin-bottom: 15px;
    color: var(--muted-red);
    font-size: 24px;
    font-weight: 700;
}

.error-modal button {
    background-color: var(--muted-red);
    color: var(--white);
    padding: 20px 50px;
    border-radius: 10px;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
}

.error-modal button:hover {
    background-color: var(--muted-red-2);
}