.welcome-popup-modal {
    border-radius: 20px;
    padding: 40px;
}

.welcome-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.welcome-popup button {
    background-color: var(--dark-bright-accent);
    color: var(--white);
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.welcome-popup button:hover {
    background-color: var(--dark-bright-accent-2);
}

.welcome-popup .popup-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 400px;
    gap: 8px;
}

.welcome-popup h1,
h2,
h3 {
    font-family: 'Inter';
    margin: 0;
}

.welcome-popup h2 {
    text-align: center;
    color: var(--dark-medium-grey);
    font-size: 18px;
    font-weight: 500;
}

.welcome-popup h1 {
    text-align: center;
    color: var(--dark-bright-accent);
    font-size: 24px;
    font-weight: 700;
}

.welcome-popup h3 {
    text-align: center;
    color: var(--dark-medium-grey);
    font-size: 16px;
    font-weight: 500;
}

.welcome-popup .name-pronouns-form {
    display: flex;
    gap: 20px;
}

.welcome-popup .name-pronouns-form label {
    color: var(--dark-medium-grey);
    text-align: left;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}


.welcome-popup .name-pronouns-form input,
select {
    border: none;
    background-color: var(--light-blue);
    color: var(--dark-accent) !important;
    height: 20px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.welcome-popup .name-pronouns-form input::placeholder {
    color: var(--dark-accent);
}

.welcome-popup .name-input-container input {
    padding: 10px 5px 10px 15px;
    width: 300px;
}

.welcome-popup .pronouns-input-container input {
    display: none;
}

.welcome-popup .pronouns-input-container input.show {
    display: block;
    margin-top: 5px;
    width: 100px;
}

.welcome-popup .pronouns-input-container select {
    padding: 10px 15px;
    width: 130px;
    height: 40px;
}