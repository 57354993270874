.video-gallery-container {
    width: calc(100vw - var(--sidebar-width) - var(--sidebar-hoz-margin) - var(--margins));
    height: 100%;
}

video-player-container.default-layout {
    display: grid !important;
    gap: 5px;
    max-width: 100%;
    max-height: calc(100% - 5px);
    aspect-ratio: 16 / 9;
}

/* Layouts */

/* 2 videos */
video-player-container.default-layout.grid-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

/* 3 videos */
video-player-container.default-layout.grid-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

video-player-container.default-layout.grid-3 > .video-div {
    grid-column-start: span 2;
}

video-player-container.default-layout.grid-3 > .video-div:nth-last-of-type(1) {
    grid-column: 2/span 2;
}

/* 4 videos */
video-player-container.default-layout.grid-4 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}
 
/* 5 videos */
video-player-container.default-layout.grid-5 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

video-player-container.default-layout.grid-5 > .video-div {
    grid-column: span 2;
}

video-player-container.default-layout.grid-5 > .video-div:nth-last-of-type(2) {
    grid-column: 2/span 2;
}

/* 6 videos */
video-player-container.default-layout.grid-6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

/* 7 videos */
video-player-container.default-layout.grid-7 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

video-player-container.default-layout.grid-7 > .video-div {
    grid-column: span 2;
}

video-player-container.default-layout.grid-7 > .video-div:first-of-type {
    grid-column: 2/span 2;
}

video-player-container.default-layout.grid-7 > .video-div:nth-last-of-type(2) {
    grid-column: 2/span 2;
}

/* 8 videos */
video-player-container.default-layout.grid-8 {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

video-player-container.default-layout.grid-8 > .video-div {
    grid-column: span 2;
}

video-player-container.default-layout.grid-8 > .video-div:nth-last-of-type(2) {
    grid-column: 2/span 2;
}

/* 9 videos */
video-player-container.default-layout.grid-9 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}

video-player-container.default-layout .pagination-controls {
    grid-column-start: 1;
    grid-column-end: -1;
}

video-player-container.screen-share-layout {
    display: grid !important;
    gap: 5px;
    max-width: 100%;
    max-height: calc(100% - 5px);
    aspect-ratio: 64 / 27;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        "video-0 sshare sshare sshare"
        "video-1 sshare sshare sshare"
        "video-2 sshare sshare sshare";
}

.video-gallery-container .video-div {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}

.video-gallery-container .video-div.placeholder {
    background-color: black;
}

.video-gallery-container video-player {
    display: block;
    width: 100%;
    height: 100%;
}

.video-gallery-container label.name-tag {
    position: absolute;
    bottom: 0;
    margin: 0;
    background-color: #00000083;
    color: white;
    font-size: 14px;
    padding: 0 5px;
}