/* Options Panel Styles */
/* Common styles */

.io-options-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    bottom: 10px;
    background-color: var(--white);
    width: 210px;
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
}

.io-options-panel.closed {
    display: none;
}

.io-options-panel h1,
li {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
}

.io-options-panel h1 {
    font-size: 16px;
}

div.source-list {
    background-color: var(--light-blue);
    border-radius: 5px;
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 14px;
    padding: 10px;
}

ul.source-list {
    list-style-type: none;
    padding: 0;
    background-color: var(--light-blue);
    border-radius: 5px;
    margin: 0;
}

.source-list li {
    display: flex;
    font-size: 11px;
    text-align: left;
    padding: 10px;
}

.source-list li .icon-container {
    display: flex;
    align-items: center;
    width: 12px;
}

.source-list li .label-container {
    width: calc(100% - 12px);
}

.source-list .icons-checkmark path {
    fill: var(--dark-accent);
}

.source-list li.selected {
    background-color: var(--light-blue-2);
}

.source-list li:hover {
    cursor: pointer;
    background-color: var(--light-blue-2);
}

.source-list li:first-child {
    border-radius: 5px 5px 0 0;
}

.source-list li:last-child {
    border-radius: 0 0 5px 5px;
}

.source-list li:first-child:last-child {
    border-radius: 5px 5px 5px 5px;
}

.io-options-panel .audio-bar {
    display: flex;
    align-items: center;
}

.io-options-panel .audio-bar progress {
    width: 100%;
}

.io-options-panel .icon-microphone-on {
    height: 25px;
    width: 30px;
}

.io-options-panel .icon-speaker-on {
    height: 25px;
    width: 32px;
}

.io-options-panel svg path {
    fill: var(--brand-accent);
}

.io-options-panel-button {
    width: 100%;
    padding: 10px 11px;
    border-radius: 5px;
    background-color: var(--dark-bright-accent);
    color: var(--white);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.io-options-panel-button:hover {
    background-color: var(--dark-bright-accent-2);
}

.io-options-panel-button.testing {
    background-color: var(--light-grey);
    color: var(--dark-medium-grey);
}

.io-options-panel-button.testing:hover {
    background-color: var(--medium-grey);
}

.io-options-panel .bottom-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.io-options-panel p {
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    text-align: left;
}