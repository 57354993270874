.screenContainer {
  width: 100%;
  height: auto;
  padding: 2% 5%;
  box-sizing: border-box;
}

.form-logo-container {
  margin-top: 1rem;         /* Increase the top margin for more space */
  padding: 1rem 0;          /* Padding to give space around the logo */
}

.logo {
  width: 120px;
  height: 39px;
  flex-shrink: 0;
  margin-left: 10px;
}

.formlogo {
  width: 50vw;             /* Logo width will be 20% of viewport width */
  max-width: 200px;        /* Set a max-width to ensure it doesn't get too big on large screens */
  min-width: 150px;          /* Maintain the aspect ratio */
  margin-bottom: 0.5rem;     /* Increase the bottom margin for more space */
}

.formContainer {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.formHeader {
  color: #4E2375;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin-bottom: 20px;
}

label, .sectionHeading, .heading {
  color: #4E2375;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 10px;
}

input[type="text"], input[type="email"] {
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #E2E5FF;
  border-radius: 8px;
  color: #83659E;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 21px;
}

.backButton {
  color: #7669FD;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  background: none;
  border: none;
  cursor: pointer;
}

.backButton:hover {
  text-decoration: underline;
  background: none;
}

textarea {
  width: 100%;
  height: 120px;
  padding: 10px 15px;
  font-size: 16px;
  border: 2px solid #E2E5FF;
  border-radius: 8px;
  color: #83659E;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 21px;
}

.contactInformation {
  width: 100%;
}

.privacyDisclaimer {
  color: #83659E;
  text-align: left; /* Adjusted alignment */
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.submitButton {
  border: none;
  border-radius: 46px;
  background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  padding: 0 21px;
  margin-right: 12px;
  margin-bottom: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #6a5eff;
}

@media screen and (max-width: 500px) {
  .formContainer {
      padding: 10px;
  }
  .submitButton {
      font-size: 12px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1000px) {
  .submitButton {
      font-size: 14px; 
  }
}

@media screen and (min-width: 1001px) {
  .submitButton {
      font-size: 16px;
  }
}

.blobContainer {
  position: fixed;
  right: 1%;
  top: 10vh; /* starting point of the blobs */
  display: flex;
  flex-direction: column;
  pointer-events: none; /* make sure blobs don't interfere with any interactions */
}

.blobContainer1 {
  position: fixed;
  right: 1%;
  top: 10vh; /* starting point of the blobs */
  display: flex;
  flex-direction: column;
  pointer-events: none; /* make sure blobs don't interfere with any interactions */
}

.blob {
  right: 10%;
  position: sticky;
  animation: floating 6s infinite;
  opacity: 0.5; /* To make them a bit transparent */
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}

.blob1 {
  top: 30%;
}

.blob2 {
  top: 50%;
}

/* Make blobs invisible on mobile screens */
@media screen and (max-width: 768px) {
  .blobContainer {
    display: none;
  }

  .logo {
    width: 50vw;
  }
}

@media screen and (min-width: 768px) {
  .screenContainer.scrolled .blob1 {
      transform: translateY(-50px) scale(1.03);
      opacity: 1;
  }
  
  .screenContainer.scrolled .blob2 {
      transform: translateY(50px) scale(1.03);
      opacity: 1;
  }
}

.radio-input {
  display: none; /* Hide the default radio button */
}

.radio-display {
  display: inline-block;
  width: 20px;  /* Adjust based on your design */
  height: 20px; /* Adjust based on your design */
  border-radius: 124px;
  border: 2px solid var(--muted-gradient-periwinkle-20, #E2E5FF);
  margin-right: 8px; /* Space between custom radio button and label text */
  vertical-align: middle;
  transition: background 0.2s, border-color 0.2s; /* Smooth transition on state change */
}

/* When the radio input is checked, adjust the style of the custom radio display */
.custom-radio .radio-input:checked + .radio-display {
  border: 2px solid var(--ui-medium-purple, #83659E);
  background: var(--muted-gradient-periwinkle-20, linear-gradient(135deg, #E2E5FF 5.73%, #E5D8FD 91.15%));
}

