.spotlight-topic-toggle {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    padding: 4px 8px;
    font-family: 'Inter';
    font-size: 10px;
    border-radius: 10px;
}

.spotlight-topic-toggle.active {
    background: var(--dark-pink-gradient, #DA61D4);
    color: var(--white);
    font-weight: 700;
}

.spotlight-topic-toggle.inactive {
    background-color: var(--pale-grey);
    color: var(--purple-grey);
    font-weight: 600;
}

.spotlight-topic-toggle.inactive path {
    fill: var(--purple-grey);
}

.spotlight-topic-toggle.inactive:hover {
    background-color: var(--dark-bright-accent);
    color: var(--white);
}

.spotlight-topic-toggle.inactive:hover path {
    fill: var(--white);
}


.spotlight-topic-toggle .icon-container {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spotlight-topic-toggle .icon-container svg {
    width: 12px;
    height: auto;
}

.spotlight-topic-toggle .icons-checkmark {
    width: auto;
    height: 10px;
}