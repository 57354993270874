.participants {
    display: flex;
    flex-direction: column;
}

.participants h1 {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
}

.participants .actions-panel {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.participants .action-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.participants .action-buttons-container button {
    padding: 5px 10px;
    border-radius: 3px;
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    background-color: var(--light-grey);
    color: var(--dark-accent);
}

.participants .action-buttons-container button:hover {
    background-color: var(--dark-accent);
    color: var(--white);
}

.participants .selected-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
}

.participants .selected-count button {
    background: none;
    padding: 0;
    width: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.participants .selected-count svg {
    max-width: 100%;
    max-height: 100%;
}

.participants .selected-count path {
    fill: var(--dark-accent);
}

.participants .selected-count.hidden {
    display: none;
}