.spotlight-tab-btn {
    display: flex;
    flex-direction: row;
    gap: 2px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
}

.spotlight-tab-btn svg {
    width: auto;
    height: 12px;
}

.spotlight-tab-btn.inactive {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--purple-grey);
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
}

.spotlight-tab-btn.inactive path {
    fill: var(--purple-grey);
}

.spotlight-tab-btn.active {
    background: var(--light-pink-gradient, --light-pink);
    border: 1px solid var(--dusty-pink);
    color: var(--muted-purple);
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
}

.spotlight-tab-btn.active path {
    fill: url(#pink_blue_gradient);
}