.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 500px;
  }
  
  .modal-content h3 {
    margin-top: 0;
    font-family: Montserrat;
  }

  .modal-content form {
    /* width: 50%; Remove or update this according to your needs */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-content form label {
    margin-top: 10px;
    font-weight: bold;
}

.modal-content form input, 
.modal-content form textarea, 
.modal-content form button {
    margin-top: 5px;
}

.modal-content form input, 
.modal-content form textarea {
    width: 80%; /* To make inputs take full width */
    padding: 10px;
    margin-bottom: 15px; /* Added margin for spacing */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-content form button {
    align-self: center; /* To center the button */
}
  
  .modal-content ul {
    list-style-type: none;
    padding: 0;
  }
  
  .modal-content li {
    margin-bottom: 10px;
  }
  
  .modal-content button {
    border: none;
    border-radius: 46px;
    background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    padding: 0 21px;
    margin-right: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 5px;
  }
  
  .modal-content button:hover {
    background-color: #6a5eff;
  }
  
  .modal-content button:disabled {
    background-color: #c6c6c6;
    cursor: not-allowed;
  }
  