body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --white: #FFFFFF;
  --dark-accent: #3B6B86;
  --dark-accent-2: #265D7B;
  --dark-bright-accent: #0080A9;
  --dark-bright-accent-2: #006B99;
  --light-blue: #E6F1F4;
  --light-blue-2: #D0E6EB;
  --light-blue-3: #BDDDE6;
  --pale-grey: #F4F4F4;
  --light-grey: #DAE3E9;
  --medium-grey: #BFCDD7;
  --medium-grey-2: #B1C2CF;
  --dark-medium-grey: #697984;
  --brand-accent: #FCA301;
  --brand-accent-2: #FC9801;
  --pale-red: #EB4458;
  --muted-red: #C63546;
  --muted-red-2: #AF2838;
  --light-pink: #F7E0FF;
  --dusty-pink: #DEA4D1;
  --purple-grey: #888BAC;
  --muted-purple: #686EA5;
  --muted-blue: #83ACD2;
  --medium-blue: #3271BF;
  --dark-navy: #182A72;
  --light-pink-gradient: linear-gradient(135deg, #F7E0FF 11.72%, #FFE5E3 90.63%);
  --dark-pink-gradient: linear-gradient(133deg, #C53EF6 22.74%, #FF7461 123.29%);
  --pink-blue-gradient: linear-gradient(231deg, #79BCE1 69%, #D4A2D4 50%, #FBB8C4 70%);
  --medium-blue-gradient: linear-gradient(100deg, #366CBE -17.14%, #0D9CC9 112.71%);
}