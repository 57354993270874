.screen-share {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.screen-share h1 {
    margin: 0;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
}

.screen-share .ss-msg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 195px;
    border-radius: 3px;
    background-color: var(--medium-grey);
}

.screen-share .ss-msg {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--dark-accent);
    color: var(--white);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
}

.screen-share button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    color: var(--white);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
}

.screen-share .share-screen-btn {
    background-color: var(--dark-bright-accent);
}

.screen-share .share-screen-btn:hover {
    background-color: var(--dark-bright-accent-2);
}

.screen-share .stop-share-btn {
    align-self: flex-end;
    background-color: var(--pale-red);
}

.screen-share .stop-share-btn:hover {
    background-color: var(--muted-red);
}

.screen-share .square {
    background-color: var(--white);
    width: 10px;
    height: 10px;
}