.agenda {
    background-color: var(--light-blue);
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    -ms-box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
}

.agenda h1 {
    margin: 0;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
}

.agenda ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.agenda li {
    padding: 10px;
    border-radius: 5px;
    display: flex;
    text-align: left;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 11px;
}

.agenda li .time {
    width: 80px;
    font-weight: 500;
}

.agenda li .topic {
    max-width: calc(100% - 80px);
    font-weight: 600;
}

.agenda li:nth-child(odd) {
    background-color: var(--light-blue-2);
}