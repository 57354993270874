.chat-announcement-modal {
    border-radius: 20px;
    padding: 40px;
}

.chat-announcement-modal .content {
    width: 470px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.chat-announcement-modal .icon-container {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-blue-2);
}

.chat-announcement-modal .icons-megaphone {
    width: auto;
    height: 30px;
}

.chat-announcement-modal .icons-megaphone path {
    fill: var(--dark-bright-accent-2);
}

.chat-announcement-modal .instructions-text {
    color: var(--dark-medium-grey);
    margin: 0;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
}

.chat-announcement-modal .msg-field {
    width: 100%;
    height: 100px;
    padding: 15px;
    margin: 0;
    border-radius: 5px;
    background-color: var(--light-blue);
    border: none;
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    resize: none;
}

.chat-announcement-modal .btns-container {
    display: flex;
    gap: 15px;
    width: 100%;
}

.chat-announcement-modal .btns-container button {
    padding: 20px 0;
    border-radius: 10px;
    text-align: center;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 600;
    flex: 1 1;
}

.chat-announcement-modal .cancel-btn {
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
}
.chat-announcement-modal .cancel-btn:hover {
    background-color: var(--light-blue-3);
}

.chat-announcement-modal .action-btn {
    background-color: var(--dark-bright-accent);
    color: var(--white);
}

.chat-announcement-modal .action-btn:hover {
    background-color: var(--dark-bright-accent-2);
}