.spotlight {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.spotlight h1 {
    margin: 0;
    color: var(--dark-navy);
    text-align: left;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 700;
}

.spotlight .tab-btns {
    display: flex;
    flex-direction: row;
    gap: 3px;
    width: fit-content;
    background-color: #EEF0F5;
    border-radius: 4px;
}

.back-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    height: 8px;
    color: var(--muted-purple);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
    margin: 4px 0;
    cursor: pointer;
}

.back-btn svg {
    width: auto;
    height: 10px;
}

.back-btn path {
    fill: var(--muted-purple);
}

.spotlight .filter-row {
    display: flex;
    justify-content: space-between;
}

.spotlight .filter-group-label {
    color: var(--purple-grey);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
    line-height: 100%;
    text-align: left;
    margin-bottom: 5px;
}

.spotlight .filter-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.spotlight .resource-filter-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.spotlight .cards-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--pale-grey);
}

.spotlight .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: flex-start;
    gap: 10px;
}

.spotlight .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Load Button */
.spotlight .load-btn {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    background-color: var(--white);
    color: var(--muted-purple);
    border: 1px solid var(--dusty-pink);
    border-radius: 20px;
    padding: 5px 8px;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
}

.spotlight .load-btn:hover {
    background: var(--light-pink-gradient, --light-pink);
}

.spotlight .icons-reload-arrow {
    width: 8px;
    height: auto;
}

.spotlight .icons-reload-arrow path {
    fill: var(--muted-purple);
}