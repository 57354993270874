.hidden {
    display: none;
}

/* MeetingRoom styles */
.meeting-room {
    --header-height: 67px;
    --header-margin: 25px;
    --footer-height: 140px;
    --footer-margin: 35px;
    --sidebar-width: 56px;
    --sidebar-hoz-margin: 15px;
    --sidebar-height: 330px;
    --sidebar-padding-max: 40px;
    --margins: 100px;
}

@media (max-width: 1241px) {
    .meeting-room {
        --footer-height: 170px;
    }
}

.meeting-room {
    height: 100vh;
    width: 100vw;
    min-height: calc(var(--header-height) + var(--header-margin) + var(--sidebar-height) + var(--sidebar-padding-max) + var(--footer-margin) + var(--footer-height));
    min-width: 640px;
    position: relative;
}

.meeting-room .header-container {
    width: 100%;
    padding-top: 15px;
    margin-bottom: 25px;
}

.meeting-room .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 52px;
}

.meeting-room .meeting-content-container {
    position: relative;
    margin: 0 50px;
    height: calc(100% - var(--header-height) - var(--header-margin) - var(--footer-height) - var(--footer-margin));
}

.meeting-room .footer-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: var(--light-blue);
}

.meeting-room .footer {
    min-height: 100px;
    max-height: 123px;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

@media (max-width: 1241px) {
    .meeting-room .footer {
        flex-direction: column;
        gap: 10px;
        max-height: 170px;
    }

    .meeting-room .time-btns-container {
        justify-content: flex-end;
    }
}

.io-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.meeting-room .time-btns-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* Closed Captions */
.cc-btn {
    background-color: var(--light-blue-2);
    height: 60px;
    width: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cc-btn:hover {
    background-color: var(--light-blue-3);
}

/* Connect inputs button */
.connect-inputs-container {
    max-height: 60px;
}

.connect-inputs-label {
    margin: 0;
    color: var(--dark-medium-grey);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
}

.connect-inputs-btn {
    background-color: var(--muted-red);
    color: var(--white);
    padding: 10px 15px;
    border-radius: 8px;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
}

.connect-inputs-btn:hover {
    background-color: var(--muted-red-2);
}

/* Debug Buttons */
.debug-btns {
    display: flex;
    gap: 3px;
}

.debug-btns button {
    background-color: lightgray;
    color: black;
    border: 1px solid black;
    border-radius: 3px;
    height: 30px;
    padding: 5px;
}

.debug-btns button:hover {
    background-color: darkgrey;
}