.content-rectangle {
  width: 633px;
  background-color: #EFEFEF;
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15) inset;
  margin-top: 30px;
}

.name {
  color: var(--ui-dark-purple, #4E2375);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.status {
  color: var(--ui-dark-purple, #4E2375);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.description {
  color: #83659E;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.22px;
  margin-bottom: 20px; /* Adjust as needed */
  text-align: left;
}

.button-container {
  display: flex;
}

.button {
  border: none;
  border-radius: 46px;
  background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  padding: 0 21px;
  margin-right: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #6a5eff;
}
