.crisis-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: var(--white);
    padding: 5px 10px 5px 50px;
    border-radius: 0 5px 5px 0;
}

.crisis-button {
    width: 40px;
    height: 40px;
    border-radius: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crisis-button.host {
    background-color: var(--light-blue);
}

.crisis-button.host:hover {
    background-color: var(--light-blue-2);
}

.crisis-button.host.active {
    background-color: var(--dark-bright-accent);
}

.crisis-button.host.active:hover {
    background-color: var(--dark-bright-accent-2);
}

.crisis-button.participant {
    background-color: var(--brand-accent);
}

.crisis-button.participant:hover {
    background-color: var(--brand-accent-2);
}

.crisis-button.participant.active {
    background-color: var(--pale-red);
}

.crisis-button.participant.active:hover {
    background-color: var(--muted-red);
}

.crisis-button.host path {
    fill: var(--dark-bright-accent);
}

.crisis-button.host.active path {
    fill: var(--white);
}

.crisis-bar .icons-exclamation-triangle {
    position: relative;
    bottom: 1px;
    width: 24px;
    height: 22px;
}

.crisis-bar .notif-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--pale-red);
    color: var(--white);
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
}

.crisis-bar .hover-text {
    color: var(--dark-medium-grey);
    width: 220px;
    margin-left: 10px;
    text-align: left;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 110%;
}

.crisis-bar .side-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.crisis-bar .active-msg {
    color: var(--muted-red);
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 700;
}

.crisis-bar .cancel-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    padding: 5px;
    border-radius: 3px;
    background-color: var(--light-grey);
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 500;
}

.crisis-bar .icons-x {
    width: 7px;
    height: 7px;
}

.crisis-bar .icons-x path {
    fill: var(--dark-accent);
}