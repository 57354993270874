/* inactive (default state) */
.meeting-button {
    display: flex;
    height: 60px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: var(--light-blue-2);
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.5px;
}
.meeting-button:hover {
    background-color: var(--light-blue-2);
}

/* active */
.meeting-button.active {
    background-color: var(--dark-bright-accent);
    color: var(--white);
}
.meeting-button.active:hover {
    background-color: var(--dark-bright-accent-2);
}

/* leave, end */
.meeting-button.leave, .meeting-button.end {
    background-color: var(--pale-red);
    color: var(--white);
}
.meeting-button.leave:hover, .meeting-button.end:hover {
    background-color: var(--muted-red);
}

/* break */
.meeting-button.break {
    background-color: var(--light-blue-2);
    color: var(--dark-bright-accent);
}
.meeting-button.break:hover {
    background-color: var(--light-blue-3);
}
