.submitButton {
    border: none;
    border-radius: 46px;
    background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    padding: 0 21px;
    margin-top: 10px;
    margin-right: 12px;
    margin-bottom: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #6a5eff;
  }

  .form-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .formlogo {
    /* You can set a specific width if needed */
    max-width: 100%;
    height: auto;
  }
  

  :root {
  --ui-white: #FFF;
  --ui-royal-purple: #4E2375;
  --ui-dark-purple: #4E2375;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.app {
  background: linear-gradient(180deg, #FFF -1.6%, #E5D8FD 29.69%, #E2E5FF 104.2%);
  opacity: 0.81;
  min-height: 100%;
  padding: 20px;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  border-radius: 35px;
  background: var(--ui-white);
  box-shadow: 6px 6px 20px 8px rgba(66, 106, 192, 0.12);
  margin: 40px 0;
  padding: 40px;
  max-width: 75vw;
  width: 100%;
  box-sizing: border-box;
}

h1, h2 {
  color: var(--ui-royal-purple);
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 20px;
}

.section p {
  color: var(--ui-royal-purple);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

label {
  display: block;
  color: var(--ui-dark-purple);
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 10px;
  margin-left: 25px;
  text-align: center;
}

textarea {
  width: calc(100% - 30px);
  height: 120px;
  padding: 10px 15px;
  font-size: 14px;
  border: 2px solid #E2E5FF;
  border-radius: 8px;
  color: #83659E;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.box {
  padding: 20px;
  margin: 50px 0;
  box-sizing: border-box;
  border-radius: 35px;
  background: var(--ui-white, #FFF);
  box-shadow: 6px 6px 20px 8px rgba(66, 106, 192, 0.12);
}

.box h3 {
  color: var(--ui-royal-purple);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.box p {
  color: var(--ui-royal-purple);
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--ui-royal-purple);
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #6B2C6D;
}

.left-svg, .right-svg {
    position: fixed;
    z-index: -1;
    top: 0;
    width: 50vw; /* Adjust based on your needs */
    height: auto;
  }
  
  .left-svg {
    left: 0;
  }
  
  .right-svg {
    right: 0;
    margin-top: 25vw;
  }
  
  
