.spotlight-card-info-table {
    color: var(--dark-accent);
    font-family: 'Inter';
    font-size: 10px;
    font-weight: 600;
    text-align: left;
}

.spotlight-card-info-table .table-header {
    border-radius: 2px 2px 0 0;
    background-color: var(--light-blue-2);
    padding: 6px;
}

.spotlight-card-info-table .table-content {
    border-radius: 0 0 2px 2px;
    border: 0.5px solid var(--light-blue-2);
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.spotlight-card-info-table .section-title {
    color: #A2ACBC;
    font-weight: 500;
}

.spotlight-card-info-table .section-info-grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

/* Address Section */
.spotlight-card-info-table .maps-link {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 2px 3px;
    height: 9px;
    border-radius: 5px;
    background-color: var(--light-blue);
    color: var(--dark-bright-accent);
    font-family: 'Inter';
    font-size: 5px;
    font-weight: 600;
}

.spotlight-card-info-table .maps-link:hover {
    background-color: var(--light-blue-2);
}

.spotlight-card-info-table .maps-link path {
    fill: var(--dark-bright-accent);
}

.spotlight-card-info-table .icons-map-pin {
    width: auto;
    height: 5px;
}

.spotlight-card-info-table .icons-caret-right {
    width: auto;
    height: 4px;
}

/* Hours Section */
.spotlight-card-info-table .interval-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.spotlight-card-info-table .interval-item .right {
    text-align: right;
}